import * as React from "react";

import {
  Alert,
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import SelfApplies from "./SelfRoom";

import {
  loadTeachers,
  teacherType,
  loadSelflearn,
  applySelfLearn,
} from "./functions";

import {
  getElementByOtherElement,
  getKeyByInnerElement,
  isEmpty,
  loadClassrooms,
  classroomType,
  leftPad,
} from "utils/functions";

import { auth, db } from "context/firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

type selfLearn = {
  id?: string;
  period: number;
  classroom?: string;
  workTeacher?: string;
  applied: boolean;
  approval?: {};
};

function Self() {
  const [teacherList, setTeacherList] = React.useState<{
    [k: string]: teacherType;
  }>({});
  const [classroomList, setClassroomList] = React.useState<{
    [k: string]: classroomType;
  }>({});

  const [isInitialLoading, setIsInitialLoading] = React.useState(true);

  const [selflearn, setSelflearn] = React.useState<selfLearn[]>([
    { period: 1, applied: false },
  ]);

  const [selectedWorkTeacherId, setSelectedWorkTeacherId] =
    React.useState<string>("");
  const [selectedClassroomId, setSelectedClassroomId] =
    React.useState<string>("");
  const [uid, setUid] = React.useState("");

  React.useEffect(() => {
    async function init() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
        }
      });
      setTeacherList(await loadTeachers());
      setClassroomList(await loadClassrooms());
    }
    init();
  }, []);

  async function loadandsetSelflearn() {
    // setUserData(await getDoc(doc(db, "user", uid)));
    setSelflearn(await loadSelflearn(uid, new Date()));
    setIsInitialLoading(false);
  }

  React.useEffect(() => {
    if (!isEmpty(teacherList) && !isEmpty(classroomList) && uid.length > 0) {
      loadandsetSelflearn();
    }
  }, [teacherList, classroomList, uid]);

  const [homeroomTeacherId, setHomeroomTeacherId] = React.useState(
    "qXbhJTJd191URSo0c6QD"
  );

  async function reloadSelflearn() {
    setSelflearn(await loadSelflearn(uid, new Date()));
  }

  async function tryApply(period: number) {
    if (selectedClassroomId !== "" && selectedWorkTeacherId !== "") {
      await applySelfLearn(uid, {
        period: period,
        homeroomTeacher: homeroomTeacherId,
        workTeacher: selectedWorkTeacherId,
        classroom: selectedClassroomId,
        date: new Date(),
      });
      await reloadSelflearn();
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Card sx={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              sx={{ p: 2 }}
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography variant="h4" align="center">
                  APPLIES
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                {isInitialLoading ? (
                  <Skeleton variant="rounded" height={58} />
                ) : (
                  <Autocomplete
                    id="requesting-classroom"
                    autoHighlight
                    autoSelect
                    options={Object.entries(classroomList).map(
                      (option) => option[0]
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="교실" variant="filled" />
                    )}
                    onChange={(e) => {
                      if (
                        Object.keys(classroomList).includes(
                          (e.target as HTMLElement).innerText
                        )
                      ) {
                        setSelectedClassroomId(
                          classroomList[(e.target as HTMLElement).innerText].id
                        );
                      } else {
                        setSelectedClassroomId("");
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                {isInitialLoading ? (
                  <Skeleton variant="rounded" height={58} />
                ) : (
                  <Autocomplete
                    id="requesting-work-teacher"
                    autoHighlight
                    autoSelect
                    groupBy={(option) =>
                      selectedClassroomId !== ""
                        ? option ===
                          getKeyByInnerElement(teacherList, {
                            id: getElementByOtherElement(
                              classroomList,
                              { id: selectedClassroomId },
                              "defaultTeacher"
                            ),
                          })
                          ? "교실에 따라 추천"
                          : "모든 선생님"
                        : "모든 선생님"
                    }
                    options={Object.entries(teacherList).map(
                      (option) => option[0]
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="지도교사"
                        variant="filled"
                      />
                    )}
                    onChange={(e) => {
                      if (
                        Object.keys(teacherList).includes(
                          (e.target as HTMLElement).innerText
                        )
                      ) {
                        setSelectedWorkTeacherId(
                          teacherList[(e.target as HTMLElement).innerText].id
                        );
                      } else {
                        setSelectedWorkTeacherId("");
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {isInitialLoading ? (
                  <Skeleton variant="rounded" height={35} />
                ) : (
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    sx={{ width: 1 }}
                  >
                    {selflearn.map((learn) => (
                      <Button
                        key={learn.period}
                        variant="contained"
                        sx={{ width: 1 }}
                        disabled={learn.applied}
                        onClick={async () => {
                          await tryApply(learn.period);
                        }}
                      >
                        {learn.period}
                      </Button>
                    ))}
                  </ButtonGroup>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {isInitialLoading
                ? [0, 1, 2].map((order) => (
                    <Grid item xs={12} key={order}>
                      <Skeleton variant="rounded" height={46} />
                    </Grid>
                  ))
                : selflearn.map((learn) => {
                    return (
                      <Grid item xs={12} key={learn.period}>
                        <SelfApplies
                          id={learn.id}
                          period={learn.period}
                          applied={learn.applied}
                          approval={learn.approval}
                          classroomName={getElementByOtherElement(
                            classroomList,
                            {
                              id: learn.classroom,
                            },
                            "short"
                          )}
                          teacherName={getKeyByInnerElement(teacherList, {
                            id: learn.workTeacher,
                          })}
                          refreshFunction={reloadSelflearn}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card sx={{ width: "100%" }}>
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">
                  PRESETS
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  id="requesting-classroom"
                  freeSolo
                  options={classrooms.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextField {...params} label="교실" variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="requesting-work-teacher"
                  freeSolo
                  options={teachers.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="지도교사"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card sx={{ width: "100%" }}>
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">
                  OTHERS
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  id="requesting-classroom"
                  freeSolo
                  options={classrooms.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextField {...params} label="교실" variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="requesting-work-teacher"
                  freeSolo
                  options={teachers.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="지도교사"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* <Snackbar open={true} autoHideDuration={6000}>
        <Alert severity="warning" sx={{ width: "100%" }}>
          This is a error message!
        </Alert>
      </Snackbar> */}
    </div>
  );
}

const classrooms = [
  { name: "2-2 교실", short: "2-2", default: "연광흠" },
  { name: "전자기학 실험실", short: "전자기학실", default: "이주형" },
  { name: "합동강의실", short: "합강", default: "지도교사없음" },
  { name: "수학교과교실2", short: "수학2실", default: "연광흠" },
  { name: "미래형 창의교실", short: "미창실", default: "최승재" },
];

const teachers = [
  { name: "연광흠" },
  { name: "이주형" },
  { name: "최승재" },
  { name: "김대용" },
  { name: "지도교사없음" },
];

export default Self;
