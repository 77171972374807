import { Card, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import SelfTable from "./SelfTable";
import SyncIcon from "@mui/icons-material/Sync";

function Teachers() {
  return (
    <Card>
      <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
        <SyncIcon fontSize="small" />
        <Typography>실시간 동기화 중</Typography>
      </Stack>

      <SelfTable />
    </Card>
  );
}

export default Teachers;
