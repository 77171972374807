import * as React from "react";

import {
  Alert,
  AlertTitle,
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import Lottie from "react-lottie";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";

import studentsAnimation from "assets/lotties/students.json";
import { isEmail } from "utils/functions";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "context/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { credStateType } from "./functions";

const studentsLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: studentsAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Login() {
  const navigate = useNavigate();
  const state = useLocation().state;

  const [cred, setCred] = React.useState<credStateType>({
    email: {
      value: "",
      error: false,
      message: "",
    },
    password: {
      value: "",
      error: false,
      message: "",
    },
    error: {
      level: undefined,
      message: "",
    },
  });

  const [info, setInfo] = React.useState({
    severity: undefined as AlertColor | undefined,
    message: "",
  });

  const [isFieldLock, setFieldLock] = React.useState(false);

  function handleCredChange(event: React.ChangeEvent<HTMLInputElement>) {
    const modify = event.target.id as "email" | "password";
    setCred({
      ...cred,
      [modify]: {
        value:
          modify === "email"
            ? event.target.value.replace(/\s+/g, "")
            : event.target.value,
        error: false,
        message: "",
      },
      error: {
        ...cred["error"],
        level: undefined,
        message: "",
      },
    });
  }

  async function handleSignin() {
    setFieldLock(true);
    setCred({
      ...cred,
      email: {
        ...cred["email"],
        error: false,
        message: "",
      },
      password: {
        ...cred["password"],
        error: false,
        message: "",
      },
      error: {
        ...cred["error"],
        level: undefined,
        message: "",
      },
    });

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        cred.email.value,
        cred.password.value
      );
      if (state?.from !== undefined) {
        navigate(state.from.pathname);
      } else {
        navigate("/dashboard");
      }
    } catch (error: any) {
      const errorCode = error.code;
      console.log(error.message);
      if (errorCode === "auth/invalid-email") {
        setCred({
          ...cred,
          email: {
            ...cred["email"],
            error: true,
            message: "올바르지 않은 형식",
          },
        });
      } else if (errorCode === "auth/user-not-found") {
        setCred({
          ...cred,
          email: {
            ...cred["email"],
            error: true,
            message: "사용자를 찾을 수 없음",
          },
        });
      } else if (errorCode === "auth/internal-error") {
        setCred({
          ...cred,
          password: {
            ...cred["password"],
            error: true,
            message: "비어 있음",
          },
        });
      } else if (errorCode === "auth/wrong-password") {
        setCred({
          ...cred,
          password: {
            ...cred["password"],
            error: true,
            message: "패스워드가 대응되지 않음",
          },
        });
      } else if (errorCode === "auth/too-many-requests") {
        setCred({
          ...cred,
          error: {
            ...cred["error"],
            level: "error",
            message: "계정이 일시적으로 잠김",
          },
        });
      } else {
        setCred({
          ...cred,
          error: {
            ...cred["error"],
            level: "error",
            message: "오류: " + error.code,
          },
        });
      }
    }
    setFieldLock(false);
    emailInputRef.current?.focus();
  }

  React.useEffect(() => {
    if (state?.from !== undefined) {
      setInfo({
        ...info,
        severity: "info",
        message: "접근하려면 로그인해야합니다",
      });
    }

    emailInputRef.current?.focus();
  }, []);

  const emailInputRef = React.useRef<HTMLInputElement>(null);
  const passwordInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Grid container sx={{ width: "100%" }} justifyContent="center">
      <Grid item sx={{ maxWidth: "100%", width: "300px" }}>
        <Card sx={{ width: "100%" }}>
          <Grid container sx={{ p: 2 }} spacing={1}>
            <Grid item xs={12}>
              {info.severity === undefined ? null : (
                <Alert severity={info.severity}>{info.message}</Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Lottie options={studentsLottieOptions} width="200px" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                통합 로그인
              </Typography>
              <Typography align="center">
                교사·학생 계정 모두 이곳에서 로그인
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-end" }}>
              {cred.email.error ? (
                <AccountCircle
                  sx={{ color: "action.active", mr: 1, my: 0.5, mb: 3.3 }}
                />
              ) : (
                <AccountCircle
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  passwordInputRef.current?.focus();
                }}
                style={{ width: "100%" }}
              >
                <TextField
                  id="email"
                  type="email"
                  label="이메일"
                  variant="standard"
                  sx={{ width: "100%" }}
                  onChange={handleCredChange}
                  disabled={isFieldLock}
                  value={cred.email.value}
                  error={cred.email.error}
                  helperText={cred.email.error ? cred.email.message : null}
                  inputRef={emailInputRef}
                />
              </form>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-end" }}>
              {cred.password.error ? (
                <PasswordIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5, mb: 3.3 }}
                />
              ) : (
                <PasswordIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              )}

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignin();
                }}
                style={{ width: "100%" }}
              >
                <TextField
                  id="password"
                  type="password"
                  label="패스워드"
                  variant="standard"
                  sx={{ width: "100%" }}
                  onChange={handleCredChange}
                  disabled={isFieldLock}
                  value={cred.password.value}
                  error={cred.password.error}
                  helperText={
                    cred.password.error ? cred.password.message : null
                  }
                  inputRef={passwordInputRef}
                />
              </form>
            </Grid>
            <Grid item xs={12}>
              {cred.error.level === undefined ? null : (
                <Alert severity={cred.error.level}>{cred.error.message}</Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleSignin}
                disabled={isFieldLock}
              >
                로그인
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button sx={{ width: "100%" }}>가입하기</Button>
            </Grid>
            <Grid item xs={12}>
              <Button sx={{ width: "100%" }} onClick={() => console.log(cred)}>
                비밀번호 분실
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Login;
