import { Button, Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import lostAnimation from "assets/lotties/lost.json";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";

function Lost() {
  const navigate = useNavigate();

  const lostLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lostAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid container sx={{ width: "100%" }} justifyContent="center">
      <Grid item sx={{ maxWidth: "100%", width: "400px" }}>
        <Card sx={{ width: "100%" }}>
          <Stack justifyContent="center" spacing={1} sx={{ p: 3 }}>
            <Typography variant="h4" align="center">
              페이지를 찾을 수 없음
            </Typography>
            <Lottie options={lostLottieOptions} width="100%" />
            <Typography align="center">저런.</Typography>
            <Stack direction="row-reverse" spacing={1} sx={{ width: "100%" }}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                홈으로
              </Button>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                이전 페이지로
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Lost;
