import * as React from "react";

import { Box, Button, Typography } from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
  collection,
  onSnapshot,
  query,
  QuerySnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "context/firebase";
import {
  classroomType,
  getKeyByInnerElement,
  loadClassrooms,
  loadUsers,
} from "utils/functions";
import { isEmpty } from "@firebase/util";
import { Stack } from "@mui/system";

import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import { loadTeachers } from "pages/Self/functions";
import clsx from "clsx";

import { leftPad } from "utils/functions";
import { changeSelfState } from "./functions";

const columns: GridColDef[] = [
  { field: "period", headerName: "교시", width: 30, align: "right" },
  {
    field: "homeroomTeacher",
    headerName: "담임교사",
    width: 70,
    align: "center",
    cellClassName: (params) => {
      return clsx("super-app", params.row.approval.homeroomTeacher);
    },
  },
  {
    field: "workTeacher",
    headerName: "지도교사",
    width: 70,
    align: "center",
    cellClassName: (params) => {
      return clsx("super-app", params.row.approval.workTeacher);
    },
  },
  { field: "name", headerName: "이름", width: 60 },
  {
    field: "classroom",
    headerName: "신청 교실",
    width: 150,
    editable: true,
  },
  {
    field: "button",
    headerName: "내 권한으로 승인",
    width: 150,
    align: "center",
    renderCell: (params) => (
      <Stack direction="row" alignItems="center">
        <Button
          variant="contained"
          onClick={async () => {
            await changeSelfState(
              params.row.id,
              "approved",
              "qXbhJTJd191URSo0c6QD"
            );
          }}
        >
          승인
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={async () => {
            await changeSelfState(
              params.row.id,
              "rejected",
              "qXbhJTJd191URSo0c6QD"
            );
          }}
        >
          거부
        </Button>
      </Stack>
    ),
  },
];

function SelfTable() {
  const [users, setUsers] = React.useState<{
    [id: string]: { [k: string]: string };
  }>({});
  const [classroom, setClassroom] = React.useState<{
    [k: string]: classroomType;
  }>({});
  const [teacher, setTeacher] = React.useState({});

  React.useEffect(() => {
    async function init() {
      setUsers(await loadUsers());
      setClassroom(await loadClassrooms());
      setTeacher(await loadTeachers());
    }

    init();
  }, []);

  const [data, setData] = React.useState<{}[]>([]);

  const q = query(
    collection(db, "selflearn"),
    where("date", "==", Timestamp.fromDate(new Date(new Date().toDateString())))
  );
  React.useEffect(() => {
    if (!isEmpty(users) && !isEmpty(classroom) && !isEmpty(teacher)) {
      onSnapshot(q, (querySnapshot) => {
        let dataList: {}[] = [];
        querySnapshot.forEach((doc) => {
          dataList.push({
            id: doc.id,
            name: users[doc.data().user]["name"],
            homeroomTeacher: getKeyByInnerElement(teacher, {
              id: doc.data().homeroomTeacher,
            }),
            workTeacher: getKeyByInnerElement(teacher, {
              id: doc.data().workTeacher,
            }),
            approval: doc.data().approval,
            period: doc.data().period,
            classroom: getKeyByInnerElement(classroom, {
              id: doc.data().classroom,
            }),
          });
        });
        setData(dataList);
      });
    }
  }, [users, classroom, teacher]);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .super-app.pending": {
          backgroundColor: "#f7f78b",
          color: "#000000",
        },
        "& .super-app.approved": {
          backgroundColor: "#66bb6a",
          color: "#000000",
        },
        "& .super-app.rejected": {
          backgroundColor: "#f44336",
          color: "#ffffff",
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        isCellEditable={() => false}
        rowHeight={30}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default SelfTable;
