import { db } from "context/firebase";
import { doc, runTransaction } from "firebase/firestore";

export async function changeSelfState(
  selfId: string,
  to: string,
  teacherId: string
) {
  try {
    await runTransaction(db, async (transaction) => {
      const targetSelfDocRef = doc(db, "selflearn", selfId);
      const targetSelfDoc = await transaction.get(targetSelfDocRef);
      if (!targetSelfDoc.exists()) {
        throw "Target selflearn does not exist!";
      }
      const prevSelf = targetSelfDoc.data();

      const totalApproval = {
        homeroomTeacher:
          prevSelf.homeroomTeacher === teacherId
            ? to
            : prevSelf.approval.homeroomTeacher,
        workTeacher:
          prevSelf.workTeacher === teacherId
            ? to
            : prevSelf.approval.workTeacher,
      };

      transaction.update(targetSelfDocRef, { approval: totalApproval });
      console.log("Transaction complete");
    });
  } catch (e) {
    console.error("Transaction failed: ", e);
  }
}
