import * as React from "react";

import {
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { calcApproval, removeSelfLearn } from "./functions";

type SelfClassroomProps = {
  period: number;
  applied: boolean;
  approval?: {};
  classroomName?: string;
  teacherName?: string;
  id?: string;
  refreshFunction?: any;
};

function SelfApplies({
  period,
  applied,
  approval,
  classroomName,
  teacherName,
  id,
  refreshFunction,
}: SelfClassroomProps) {
  const [isDeleteLocked, setDeleteLock] = React.useState(false);

  const colors = {
    pending: "#eb850e",
    approved: "#66bb6a",
    rejected: "#f44336",
  };

  return (
    <Card
      sx={{
        width: "100%",
        bgcolor:
          applied && approval !== undefined
            ? colors[calcApproval(approval)]
            : "#23272e",
      }}
    >
      <Stack direction="row" alignItems="center">
        <Typography
          variant="h5"
          sx={{ p: 1, minWidth: "15px" }}
          align="center"
          color="common.black"
          bgcolor="common.white"
        >
          {period}
        </Typography>
        <Grid
          container
          direction="row"
          sx={{ width: "100%", pl: 1 }}
          alignItems="center"
        >
          <Grid item>
            {applied ? (
              <Typography>
                <b>{classroomName}</b>
              </Typography>
            ) : (
              <Typography>비어 있음</Typography>
            )}
          </Grid>
          <Grid item>
            <Typography>{applied ? "(" + teacherName + ")" : null}</Typography>
          </Grid>
          <Grid item xs></Grid>
          {!applied ? null : (
            <IconButton
              disabled={isDeleteLocked}
              onClick={async () => {
                setDeleteLock(true);
                await removeSelfLearn(id as string);
                await refreshFunction();
                setDeleteLock(false);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Stack>
    </Card>
  );
}

export default SelfApplies;
