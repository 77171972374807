import { Card, Grid, Typography } from "@mui/material";
import checkingAnimation from "assets/lotties/checking.json";
import Lottie from "react-lottie";

function ValidateAuth() {
  const checkingLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: checkingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid container sx={{ width: "100%" }} justifyContent="center">
      <Grid item sx={{ maxWidth: "100%", width: "400px" }}>
        <Card sx={{ width: "100%" }}>
          <Grid container justifyContent="center" spacing={1} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                계정 권한 검사중...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Lottie options={checkingLottieOptions} width="100%" />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">
                이 화면이 오래 지속되면 새로고침하세요
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ValidateAuth;
