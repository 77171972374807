import * as React from "react";
import "assets/global.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Dashboard from "pages/Dashboard";
import Self from "pages/Self";
import Lost from "pages/Lost";
import Login from "pages/Login";
import ValidateAuth from "pages/ValidateAuth";
import Teachers from "pages/Teachers";

import ResponsiveAppBar from "components/ResponsiveAppBar";

import { auth } from "context/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Box } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import ProtectedLayout from "components/ProtectedLayout";

const THEME = createTheme({
  typography: {
    fontFamily: `"sf-pro", "sd-gothic"`,
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  components: {
    // MuiTextField:{
    //   defaultProps:{

    //   }
    // }
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        noOptionsText: "옵션 없음",
      },
    },
  },
  palette: {
    // primary: {
    //   main: "#3182f6",
    //   light: "#3182f6",
    //   dark: "#3182f6",
    // },
    // secondary: {
    //   main: "#e3e4e7",
    //   light: "#e3e4e7",
    //   dark: "#323237",
    // },
    // text: {
    //   primary: "#e4e4e5",
    //   secondary: "39e9ea4",
    // },
    //
    mode: "dark",
  },
});

function App() {
  const [uid, setUid] = React.useState<string | undefined>(undefined);

  const [alert, setAlert] = React.useState({
    severity: undefined as AlertColor | undefined,
    message: "",
    hideIn: 6000,
  });

  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(undefined);
      }
    });
  }, []);

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({
      ...alert,
      severity: undefined,
      message: "",
      hideIn: 6000,
    });
  };

  return (
    <ThemeProvider theme={THEME}>
      <BrowserRouter>
        <ResponsiveAppBar />
        <Box sx={{ p: 1 }}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Lost />} />
            <Route path="/validate" element={<ValidateAuth />} />
            <Route element={<ProtectedLayout />}>
              <Route path="/self" element={<Self />} />
              <Route path="/teachers" element={<Teachers />} />
            </Route>
          </Routes>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
